
<template>
  <LiefengContentManage>
    <template v-slot:title>社区出入口管理</template>
    <template v-slot:toolsbarRight>
      <template>
        <Form class="search" :label-width="100" :model="formItem" :label-colon="true" :inline="true">
          <FormItem label="范围" :label-width="80">
            <Cascader
                v-model="orgCodeArray"
                :data="publishList"
                :load-data="loadCommunity"
                change-on-select
            ></Cascader>
          </FormItem>
          <FormItem label="出入类型" :label-width="80" style="margin-right: 10px;text-align: left;">
            <Select style="width: 180px" v-model="formItem.positionType" placeholder="请选择出入类型搜索">
              <Option value="0">全部</Option>
              <Option :value="item.dictKey" v-for="(item,index) in credentialsList" :key="index">{{item.dictValue}}</Option>
            </Select>
          </FormItem>
          <FormItem label="出入口地点">
            <Input v-model="formItem.positionName" placeholder="填写出入地点搜索"></Input>
          </FormItem>
          <template>
            <Button type="primary" icon="ios-search-outline" style="margin-left: 10px; " @click="search">搜索</Button>
            <Button type="success" icon="ios-refresh" style="margin-left: 10px;" @click="reset">重置</Button>
          </template>
          <Button type="primary" style="margin-left: 10px;" @click="accessRecords">出入记录</Button>
          <Button type="info" style="margin-left: 10px;" @click="FormNewsFn">新增</Button>
        </Form>
      </template>
    </template>
    <template v-slot:contentArea>
      <div class="container">
        <!--       出入码 -->
        <LiefengErwma
            :fullscreen="false"
            :value="toggle1"
            :toggle="true"
            :textObj="textObj"
            :toggle-fn2="toggleFn2"
            :toggleType="toggleType"
        >
        </LiefengErwma>

        <!--      新增出入口  -->
        <LiefengModal :title="newflag" width="62%" height="calc(100vh - 100px)" @input="canceDown" :value="cancelStatus">
          <template #contentarea>
            <Liefengaccess
                v-if="cancelStatus"
                ref="formTableCom"
                :echoToggle="echoToggle"
                :nodeList="nodeList"
                :allCommunityList="allCommunityList"
                :loadingCommunityFn="loadingCommunityFn"
   
            >

            </Liefengaccess>
          </template>
          <template #toolsbar>
            <Button type="info" style="margin-right: 10px" @click="canceDown(false)">取消</Button>
            <Button type="primary" @click="cancelSave">确定</Button>
          </template>
        </LiefengModal>

        <LiefengTable
            :talbeColumns="tableColumns"
            :tableData="tableData"
            :loading="loading"
            :fixTable="true"
            :curPage="page"
            :total="total"
            :pagesizeOpts="[20, 30, 50, 100]"
            :pageSize="pageSize"
            @hadlePageSize="hadlePageSize"
            @tableSelect="tableSelect"
        ></LiefengTable>
      </div>
    </template>
  </LiefengContentManage>
</template>

<script>
//@route('/communityaccess')
import LiefengTable from "@/components/LiefengTable";
// import LiefengContentManage from "@/views/childmanagement/LiefengContentManage";
import LiefengContentManage from '@/components/LiefengContent'
import LiefengModal from "@/components/LiefengModal";
import Liefengaccess from "@/views/communityaccess/Liefengaccess";
import LiefengErwma from "@/views/communityaccess/LiefengErwma";


export default {
  components: {
    LiefengTable,
    LiefengContentManage,
    LiefengModal,
    Liefengaccess,
    LiefengErwma
  },
  data() {
    return {
      //状态显示二维码
      toggleType: {},
      resData: '',

      //获取社区列表
      nodeList: [],
      allCommunityList: [],

      //生成二维码
      toggle1: false,
      textObj: {
        spinShow: false,
        signInUrl: '', //出的 二维码url
        signInUrlEnter:'',  //入的 二维码
      },
      erwemaToggle: false,
      //新增修改
      newflag: '',
      echoToggle: false, //新增/修改状态

      credentialsList: [],

      cancelStatus: false,

      // 发布范围列表
      publishList: [],

      //搜索
      orgCodeArray: [],
      formItem: {},

      tableSelectArr: [], //table选中的
      //table列表
      tableColumns: [
        {
          type: "selection",
          width: 60,
          align: "center"
        },
        {
          title: '序号',
          align: "center",
          width: 70,
          render: (h, params) => {
            return h("span", {}, params.index + 1)
          }
        },
        {
          title: '社区',
          key: 'orgName',
          width: 150,
          align: "center",
        },
        {
          title: '出入口地点',
          key: 'positionName',
          align: "center",
          minWidth: 130
        },
        {
          title: '经纬度',
          key: 'latitude',
          align: "center",
          width: 140,
          render: (h, params) => {
            let arr = params.row.latitude + ',' + params.row.longitude
            return h('div', [
              h('span', {},
                  arr
              )
            ])
          }
        },
        {
          title: '出入类型',
          key: 'positionType',
          width: 130,
          align: "center",
          render: (h, params) => {
            let arr2 = ''
            arr2 = this.credentialsList.map(item => {
              return item.dictKey === params.row.positionType ? item.dictValue : ''
            })
            return h('div', [
              h('span', {},
                  arr2)
            ])
          }
        },
        {
          title: '每户出入限制人数',
          // key: 'idType',
          width: 180,
          align: "center",
          render: (h, params) => {
            return h('div', [
              h('span', {},
                  // params.row.paraList[0].paraValue
              '不限')
            ])
          }
        },
        {
          title: '创建时间',
          key: 'gmtCreate',
          width: 170,
          align: "center",
        },
        {
          title: '状态',
          key: 'status',
          width: 120,
          align: "center",
          render: (h, params) => {
            return h("div", [
              h("i-switch", {
                props: {
                  value: params.row.status,
                  size: "large",
                  "true-value": "1",
                  "false-value": "2",
                  "before-change": this.beforeChangeSwitch,
                },
                scopedSlots: {
                  open: () => h("span", "启用"),
                  close: () => h("span", "禁用"),
                },
                on: {
                  "on-change": (val) => {
                    this.changeSwitch(val, params.row);
                  },
                },
              }),
            ]);
          },
        },
        {
          title: '操作',
          key: 'action',
          fixed: 'right',
          align: "center",
          width: 220,
          render: (h, params) => {
            let data = params.row
            return h('div', [
              h('Button', {
                props: {
                  type: 'success',
                  size: 'small',
                },
                style: {
                  marginRight: '3px'
                },
                on: {
                  click: () => {
                    this.accessCode(data)
                  }
                }
              }, '生成出入码'),
              h('Button', {
                props: {
                  type: 'info',
                  size: 'small',
                },
                style: {
                  marginRight: '3px'
                },
                on: {
                  click: () => {
                    this.Modify(data)
                  }
                }
              }, '修改'),
              h('Button', {
                props: {
                  type: 'error',
                  size: 'small',
                },
                style: {},
                on: {
                  click: () => {
                    this.deleteFn(data)
                  }
                }
              }, '删除'),
            ]);
          }
        }
      ],
      tableData: [], //table的数据
      loading: false, //table 加载
      page: 1, //分页
      total: 0,   //总数量
      pageSize: 20, //每一页显示的数量
    };
  },
  mounted() {
    //获取所属社区列表
    // let staffId = parent.vue.loginInfo.userinfo.id;
    this.$get("/gx/pc/staff/selectDatsScopeAndFilterLevel", {
      custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
      orgCode: parent.vue.loginInfo.userinfo.orgCode,
      filterLevel: 5,
      oemCode: parent.vue.oemInfo.oemCode
    }).then((res) => {
      if (res.code == 200 && res.dataList && res.dataList.length > 0) {
        this.nodeList = res.dataList.map((item) => {
          return {
            value: item.orgCode,
            label: item.orgName,
          };
        });
        this.allCommunityList = JSON.parse(JSON.stringify(this.nodeList));
        if (this.nodeList.length > 50) {
          this.nodeList.length = 50;
        }
      } else {
        // this.$Message.error({
        //   background: true,
        //   content: res.desc
        // });
      }
    }).catch(err => {
      this.$Message.error({
        background: true,
        content: "获取数据失败"
      });
    });


    //字典
    this.$get("/old/api/common/sys/findDictByDictType", {
      dictType: 'COMMUNITY_POSITION_TYPE',
    }).then((res) => {
      if (res.code == 200 && res.dataList && res.dataList.length > 0) {
        this.credentialsList = res.dataList
      } else {
        this.$Message.error({
          background: true,
          content: res.desc
        });
      }
    }).catch(err => {
      this.$Message.error({
        background: true,
        content: "获取数据失败"
      });
    });

    // 获取广州市的区
    this.getPulishData(4401, "publishList");


    this.hadlePageSize({
      page: this.page,
      pageSize: this.pageSize
    });
  },
  beforeMount() {
    this.tableHeight = window.innerHeight - 200 + '';
  },
  methods: {
    //异步加载社区
    loadingCommunityFn(query) {
      if (query !== '' && !Number(query)) {
        this.loadingCommunity = true;
        this.nodeList = this.allCommunityList.filter(item => item.label.toLowerCase().indexOf(query.toLowerCase()) > -1);
        if (this.nodeList.length > 50) this.nodeList.length = 50;
        this.loadingCommunity = false;
      } else if (Number(query)) {
        return;
      } else {
        this.nodeList = [];
      }
    },

    //出入记录
    accessRecords() {
      this.$router.push({
        path: '/recordsAcc',
        query: {menuId: this.$route.query.menuId}
      })
    },

    // 获取区
    getPulishData(code, list) {

      this.$get("/old/api/pc/staff/selectCascadeDataScope", {
        orgCode: code,
        oemCode: parent.vue.oemInfo.oemCode,
        orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
        custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId
      }).then(res => {
        if (res.code == 200) {
          this[list] = res.dataList.map(item => {
            return {
              value: item.orgPath,
              label: item.orgName,
              orgLevel: item.orgLevel,
              loading: false,
              children: []
            };
          });
        }
      });
    },

    // 动态加载街道/社区
    loadCommunity(item, callback) {
      item.loading = true;
      let arrCode = item.value.split("-");
      this.$get("/old/api/pc/staff/selectCascadeDataScope", {
        orgCode: arrCode[arrCode.length - 1],
        oemCode: parent.vue.oemInfo.oemCode,
        orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
        custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId
      }).then(res => {
        if (res.code == 200) {
          if (!item.index) {
            item.children = res.dataList.map(item => {
              return {
                value: item.orgPath,
                label: item.orgName,
                orgLevel: item.orgLevel,
                loading: false,
                children: [],
                index: 1
              };
            });
          } else {
            item.children = res.dataList.map(item => {
              return {
                value: item.orgPath,
                label: item.orgName,
                orgLevel: item.orgLevel,
                index: 1
              };
            });
          }
          item.loading = false;
          callback();
        }
      });
    },


    tableSelect(data) { //表格选中
      this.tableSelectArr = JSON.parse(JSON.stringify(data));
      this.tableSelectDataId = data
    },


    //选择器
    changeSelect(val) {
    },
    //取消
    canceDown(status) {
      this.cancelStatus = status
    },

    //新增
    FormNewsFn() {
      this.cancelStatus = true
      this.newflag = '新增出入口'
      this.echoToggle = false
    },


    //新增确定
    cancelSave() {
      if (this.echoToggle === false) { //新增
        const _data = this.$refs.formTableCom.getFormData()
        let list = []
        let isTrue = false
        let positionList = []
        _data.data.map(item=>{
          if(JSON.stringify(item) !== "{}"){
            list.push(item)
          }
        })
        // 判断社区有没有选
         if (!_data.type) {
            this.$Message.warning({
               content:'请先选择社区',
               background:true
             })
             return
          } 
        // 如果有全部为空
        if(list.length == 0){
          this.$Message.warning({
            content:'请填写至少一条出入口信息',
            background:true
          })
          return
        }else{
          // 如果只是某一条为空
          list.map(item=>{
            if(item.positionName != undefined && item.input != undefined && item.paraValue!= undefined && item.positionType!= undefined){
              if(item.positionName == '' || item.input == '' || item.paraValue == '' || item.positionType == ''){
                 this.$Message.warning({
                  content:'请完善出入口信息',
                  background:true
                })
                isTrue = false
                return
              }else{
                isTrue = true
                if((item.input instanceof Array) == false){
                     item.input = item.input.split(',')
                }
                positionList.push({
                  paras: [
                    {
                      paraCode: 'personLimit', //配置代码
                      paraValue: item.paraValue, //配置值
                    }
                  ],
                  latitude:item.input[0],
                  longitude:item.input[1],
                  positionName:item.positionName,
                  positionType:item.positionType
                })
                }   
              }else{
                this.$Message.warning({
                  content:'请完善出入口信息',
                  background:true
                })
                isTrue = false
                return
              }
          })
        }
        if(isTrue == true){
          let params = {
            orgCode: _data.type,
            positionList,
            selfOrgCode: parent.vue.loginInfo.userinfo.orgCode, //归属机构代码
          }

          this.$post('/datamsg/api/pc/community/position/addPositionList', params, {"Content-Type": "application/json"})
              .then(res => {
                if (res.code == '200') {
                  this.$Message.success({
                    background: true,
                    content: '新增成功'
                  });
                  this.cancelStatus = false
                  this.hadlePageSize({
                    page: this.page,
                    pageSize: this.pageSize
                  });
                } else {
                  this.$Message.error({
                    background: true,
                    content: res.desc
                  });
                }
              }).catch(err => {
            this.$Message.error({
              background: true,
              content: "获取数据失败"
            });
          });
        }
      
      } else if (this.echoToggle === true) {  //修改
        const _data = this.$refs.formTableCom.getFormData()
        let arr = ''
        arr = _data.data[0].input.split(',')
        if(_data.data[0].positionName == '' || _data.data[0].paraValue == '' || _data.data[0].positionType == '' || _data.data[0].input == '' ){
          this.$Message.warning({
            content:'请完善出入口信息',
            background:true
          })
          return
        }
        let params = {
          id: _data.data[0].id,
          latitude: arr[0],
          longitude: arr[1],
          paras: [
            {
              paraCode: 'personLimit', //配置代码
              paraValue: _data.data[0].paraValue, //配置值
            }
          ],
          positionName: _data.data[0].positionName,
          positionType: _data.data[0].positionType,
          status: _data.data[0].status,
        }

        this.$post("/datamsg/api/pc/community/position/updatePosition", params, {"Content-Type": "application/json"}).then((res) => {
          if (res.code == 200) {
            this.$Message.success({
              background: true,
              content: '修改成功',
            });
            this.cancelStatus = false
            this.hadlePageSize({
              page: this.page,
              pageSize: this.pageSize
            });
          } else {
            this.$Message.error({
              background: true,
              content: res.desc,
            });
          }
        }).catch(err => {
          this.$Message.error({
            background: true,
            content: "获取数据失败"
          });
        })
      }
    },


    //取消二维码的弹窗
    toggleFn2() {
      this.toggle1 = false
    },

    loadDome() {
      this.$Message.loading({
        content: "正在加载数据，请稍等...",
        duration: 0,
      });
    },

    //生成出入码

    accessCode(data) {
      this.toggleType = data
      if (data.positionType === '2') {

        this.loadDome()
        this.$get('/gx/pc/organization/coverProjectCodeByOrgCode', {
          orgCode: data.orgCode
        }).then((res) => {
          if (res.code == '200') {
            this.resData = res.data
          } else {
            this.$Message.destroy()
            this.$Message.error({
              background: true,
              content: res.desc
            });
          }
        }).then(res => {
          this.outType(data)
        })

      } else if (data.positionType === '1') {

        this.loadDome()
        this.$get('/gx/pc/organization/coverProjectCodeByOrgCode', {
          orgCode: data.orgCode
        }).then((res) => {
          if (res.code == '200') {
            this.resData = res.data
          } else {
            this.$Message.destroy()
            this.$Message.error({
              background: true,
              content: res.desc
            });
          }
        }).then(res => {
          this.enterType(data)
        })


      } else if (data.positionType === '3') {

        this.loadDome()
        this.$get('/gx/pc/organization/coverProjectCodeByOrgCode', {
          orgCode: data.orgCode
        }).then((res) => {
          if (res.code == '200') {
            this.resData = res.data
          } else {
            this.$Message.destroy()
            this.$Message.error({
              background: true,
              content: res.desc
            });
          }
        }).then(res => {
          this.outType(data)
          this.enterType(data)
        })


      }
    },

    outType(data) { //出
      //出
      this.$post("/datamsg/api/pc/wxqr/createWxAppletQr",
          {
            type: "GOOUT_SHARE",
            value: JSON.stringify({
              path: "pages/welcome/welcome",
              redirectPath: "/modules/2021/pages/orgAssessControl/index",
              params: {
                projectName: data.positionName,
                projectCode: this.resData,
                positionId: data.id,
                positionType: '2',
              }
            })
          },
          {"Content-Type": "application/json"}
      ).then(res => {
        if (res.code == 200 && res.data) {
          this.textObj.signInUrl = res.data.codeUrl;
          this.toggle1 = true
          this.$Message.destroy()
        } else {
          this.$Message.destroy()
          this.$Message.error({
            background: true,
            content: res.desc
          });
        }
      });
    },
    enterType(data) { //入

      this.$post("/datamsg/api/pc/wxqr/createWxAppletQr",   //入
          {
            type: "INCOME_SHARE",
            value: JSON.stringify({
              path: "pages/welcome/welcome",
              redirectPath: "/modules/2021/pages/orgAssessControl/index",
              params: {
                projectName: data.positionName,
                projectCode: this.resData,
                positionId: data.id,
                positionType: '1',
              }
            })
          },
          {"Content-Type": "application/json"}
      ).then(res => {
        if (res.code == 200 && res.data) {
          this.textObj.signInUrlEnter = res.data.codeUrl;
          this.toggle1 = true
          this.$Message.destroy()
        } else {
          this.$Message.destroy()
          this.$Message.error({
            background: true,
            content: res.desc
          });
        }
      });
    },


    //修改
    Modify(data) {
      const toggle = true
      this.cancelStatus = true
      this.newflag = '修改出入口'
      this.echoToggle = true; //新增/修改状态
      //回显
      if (toggle === true) {
        this.$get('/datamsg/api/pc/community/position/selectPositionById', {    //liang
          positionId: data.id
        }).then(res => {
          if (res.code == 200 && res.data) {
            this.formLeftData = res.data
            this.$refs.formTableCom.save(this.formLeftData)
          }
        }).catch(err => {
          this.loading = false;
          this.$Message.error({
            background: true,
            content: "获取数据失败"
          });
        });
      }

    },
    //删除
    deleteFn(data) {
      this.$Modal.confirm({
        title: "温馨提示",
        content: `<p>确定删除该条数据吗？</p>`,
        onOk: () => {
          this.$post("/voteapi/api/pc/community/position/deletePosition", {
            positionIds: data.id
          })
              .then(res => {
                if (res.code == 200) {
                  this.$Message.success({
                    background: true,
                    content: "删除成功！"
                  });
                  this.hadlePageSize({
                    page: this.page,
                    pageSize: this.pageSize
                  });
                } else {
                  this.$Message.error({
                    background: true,
                    content: "删除失败！"
                  });
                }
              })
              .catch(err => {
                this.$Message.error({
                  background: true,
                  content: "删除失败！"
                });
              });
        }
      });
    },

    beforeChangeSwitch() {
      return new Promise((resolve) => {
        this.$Modal.confirm({
          title: "切换确认",
          content: "您确认要切换状态吗？",
          onOk: () => {
            resolve();
          },
        });
      });
    },
    //   切换状态
    changeSwitch(val, data) {
      let params = {
        id: data.id,
        latitude: data.latitude,
        longitude: data.longitude,
        paras: [
          {
            paraCode: 'personLimit', //配置代码
            paraValue: data.paraList[0].paraValue, //配置值
          }
        ],
        positionName: data.positionName,
        positionType: data.positionType,
        status: val,
      }

      this.$post("/datamsg/api/pc/community/position/updatePosition", params, {"Content-Type": "application/json"}).then((res) => {
        if (res.code == 200) {
          this.$Message.success({
            background: true,
            content: '操作成功',
          });
        } else {
          this.$Message.error({
            background: true,
            content: res.desc,
          });
        }
      }).catch(err => {
        this.$Message.error({
          background: true,
          content: "获取数据失败"
        });
      })
    },

    //搜索
    search() {
      let arr = this.orgCodeArray[this.orgCodeArray.length - 1]
      if (arr) {
        let arr1 = arr.split('-')
        this.formItem.orgCode = arr1[arr1.length - 1]
      }else {
        this.formItem.orgCode=''
      }

      this.hadlePageSize({
        page: this.page,
        pageSize: this.pageSize
      });


    }
    ,
    //重置
    reset() {
      this.formItem = {};
      this.orgCodeArray = []
      this.hadlePageSize({
        page: this.page,
        pageSize: this.pageSize
      });
    }
    ,

    hadlePageSize(obj) {
      this.loading = true;
      this.formItem.positionType = this.formItem.positionType === '0' ? '' : this.formItem.positionType
      this.$get("/datamsg/api/pc/community/position/queryByPositionPage", {
        orgCode: this.formItem.orgCode ? this.formItem.orgCode : '4401',
        positionType: this.formItem.positionType,
        positionName: this.formItem.positionName,
        custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
        selfOrgCode: parent.vue.loginInfo.userinfo.orgCode,
        page: obj.page,
        pageSize: obj.pageSize,
      }).then(res => {
        this.loading = false;
        if (res.code == 200 && res.dataList) {

          res.dataList.map(item => {
            item.gmtCreate = item.gmtCreate
                ? this.$core.formatDate(
                    new Date(item.gmtCreate),
                    "yyyy-MM-dd hh:mm:ss"
                )
                : "";
          });

          this.tableData = res.dataList;
          this.page = res.currentPage;
          this.pageSize = res.pageSize;
          this.total = res.maxCount;

        } else {
          this.$Message.error({
            background: true,
            content: res.desc
          });
        }
      })
          .catch(err => {
            this.loading = false;
            this.$Message.error({
              background: true,
              content: "获取数据失败"
            });
          });
    },
  },
};
</script>

<style scoped lang='less'>
.container {
  height: calc(100vh - 85px);
  width: calc(100vw - 20px);
}


/deep/ #toolsbarRight {
  width: 1000%;
}

.header-wrapper {
  display: flex;
}

.headerClass {
  display: flex;
  width: 100%;
  text-align: right;

  /deep/ .ivu-form-item {
    margin-bottom: 0;

    .ivu-form-item-content {
      input {
        width: 180px;
      }
    }

    .ivu-select-selection {
      width: 180px;
    }
  }
}


/deep/ #toolsbarRight {
  width: 1000%;

  .search {
    width: 100%;
    text-align: right;

    .ivu-form-item {
      margin-bottom: 0;
    }
  }
}

</style>


